<template>
<div>

<div class="container-fluid">

    <div class="row">
        <div class="col-2 border p-3">
            <h4 class="text-center mb-1">Завершение разговора</h4>
            <button id="end-success" type="button" class="btn btn-success my-1" v-on:click="stopTalk(0)">Успех</button>
            <button id="end-fail"    type="button" class="btn btn-danger my-1"  v-on:click="stopTalk(1)">Отказ</button>
            <button id="end-recall"  type="button" class="btn btn-info my-1"    v-on:click="stopTalk(2)">Перезвонить</button>
            <button id="end-cancel"  type="button" class="btn btn-warning my-1" v-on:click="stopTalk(3)">Не учитывать</button>

            <hr>

            <p>
                {{ crmName }}<br>
                {{ crmTel }}<br>
                {{ crmEmail }}
            </p>

            <hr>

            <h4 class="text-center mt-1 mb-1">Блоки</h4>

            <div v-for="block in ssData.blocks" v-bind:key="block.id + '-steps'">
                <button v-if="block.type == 0" :id="'step-' + block.id" :data-block-id="block.id" class="btn btn-secondary btn-block my-1">{{block.name}}</button>
            </div>

        </div>

        <div class="col-5 border p-3">
            <h4 class="text-center mb-5">Фразы и ответы</h4>

            <div id="phrases" class="p-2" style="background-color: #e5ffde; font-size:1.2em"></div>

            <div id="inputs" class="mt-3"></div>

            <hr>

            <div id="answers" class="d-flex mt-3"></div>

        </div>

        <div class="col-3 border p-3">
            <h4 class="text-center mb-5">История разговора</h4>

            <div contenteditable="true" class="border p-1" id="talk-log" style="max-height: 500px; overflow:auto"></div>
            <div class="input-group mb-3">
                <input type="text" class="form-control" id="message">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary" type="button" id="button-send-message">Отправить</button>
                </div>
            </div>
        </div>

        <div class="col-2 border p-3">
            <h4 class="text-center mb-5">Вопросы и возражения</h4>

            <div v-for="block in ssData.blocks" v-bind:key="block.id + '-objections'">
                <button v-if="block.type == 1" :id="'objection-' + block.id" :data-block-id="block.id" class="btn btn-secondary btn-block my-1">{{block.name}}</button>
            </div>

            <div v-for="block in ssData.blocks" v-bind:key="block.id + '-questions'">
                <button v-if="block.type == 2" :id="'question-' + block.id" :data-block-id="block.id" class="btn btn-secondary btn-block my-1">{{block.name}}</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="stopScriptSale" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Завершение скрипта продаж</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form @submit.prevent="submit" action="" method="POST">
                <input id="input-id" name="id" type="hidden" value="">
                <div class="modal-body">
                    <div class="form-group">
                        <label for="frmName">ФИО:</label>
                        <input name="name" type="text" class="form-control" id="frmName" placeholder="Иванов Иван Иванович" v-model="crmName">
                    </div>
                    <div class="form-group">
                        <label for="frmEmail">Email:</label>
                        <input name="email" type="email" class="form-control" id="frmEmail" placeholder="name@example.com" v-model="crmEmail">
                    </div>
                    <div class="form-group">
                        <label for="frmTel">Телефон:</label>
                        <input name="tel" type="text" class="form-control" id="frmTel" placeholder="+71234567890" v-model="crmTel">
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="submit" v-on:click="submit" class="btn btn-secondary" data-dismiss="modal">Завершить</button>
                </div>
            </form>
        </div>
    </div>
</div>

</div>
</template>



<script>
    export default {
        props: {
            salesScriptsData: { type: String, required: true },
            manager: { type: String, required: true },
            routeStop: { type: String, required: true },
            routeReturn: { type: String, required: true },
            crmEmail:  { type: String, required: true },
            crmTel: { type: String, required: true },
            crmName: { type: String, required: true },
        },
        data() {
            this.ssData = JSON.parse(this.salesScriptsData);
            this.managerData = JSON.parse(this.manager);
            this.status=3;

            return {
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                fields: {},
            }
        },
        mounted: function()  {
            this.start();
        },
        methods: {
            start: function() {
                var thisTpl = this;
                var phrase = '';
                Cookies.remove('slots');

                $('[data-block-id]').click(function(){
                    phrase = thisTpl.findFirstPhrase( $(this).data('block-id') );
                    thisTpl.nextPhrase(phrase);
                });

                if (this.ssData.start_block < 1) {
                    $('#phrases').html('<div class="alert alert-danger" role="alert">Не задан стартовый блок в настройках скрипта</div>');
                    return 1;
                }
                $('#step-' + this.ssData.start_block).prop( "disabled", true )
                var block = this.getBlockById(this.ssData, this.ssData.start_block);

                if (block.start_phrase < 1) {
                    $('#phrases').html('<div class="alert alert-danger" role="alert">Не задана стартовая фраза в настройках блока</div>');
                    return 1;
                }

                $('#button-send-message').click(function() {
                    thisTpl.addMessageInfo( $('#message').val() );
                    $('#message').val('');
                });

                $('#end-success').click(function() {
                    var inputs = thisTpl.getInputs(phrase);
                    $('#inputs').html(inputs);
                });

                $('#end-fail').click(function() {
                    var inputs = thisTpl.getInputs(phrase);
                    $('#inputs').html(inputs);
                });

                $('#end-recall').click(function() {
                    var inputs = thisTpl.getInputs(phrase);
                    $('#inputs').html(inputs);
                });

                this.nextPhrase(block.start_phrase);
            },
            getBlockById: function(salesScript, blockId) {
                var block = 0;
                salesScript.blocks.forEach(element => {
                    if (element.id == blockId) block = element;
                });
                return block;
            },
            getPhraseById: function(block, phraseId) {
                var phrase = 0;
                block.phrases.forEach(element => {
                    if (element.id == phraseId) phrase = element;
                });
                return phrase;
            },
            findFirstPhrase: function(blockID) {
                var phraseID = 1;
                this.ssData.blocks.forEach(element => {
                    if (element.id == blockID) {
                        phraseID = element.start_phrase
                    }
                });
                return phraseID;
            },
            findBlockByPhrase: function(phraseID) {
                var block = new Array();
                this.ssData.blocks.forEach(element => {
                    element.phrases.forEach(el => {
                        if (el.id == phraseID) {
                            block = element;
                        }
                    });
                });
                return block;
            },
            getAnswers: function(phrase) {
                var answers = '';
                phrase.answers.forEach(element => {
                    var bclass = 'btn-secondary';
                    if (element.status == 0) bclass = 'btn-success';
                    if (element.status == 1) bclass = 'btn-warning';
                    if (element.status == 2) bclass = 'btn-danger';
                    answers += '<button class="mx-1 btn '+bclass+' btn-answer" data-next-phrase="'+element.next_sales_scripts_phrase_id+'">' + element.text + '</button>'
                });
                return answers;
            },
            getInputs: function(phrase) {
                var inputs = '';

                if (Array.isArray(phrase.inputs)) {
                    phrase.inputs.forEach(element => {

                        if (element.type == 0) {
                            inputs += '<div class="form-group">';
                            inputs += '<label>'+element.label+'</label>';
                            inputs += '<input type="text" class="form-control" data-label="'+element.label+'" name="'+element.slot+'">';
                            inputs += '</div>';
                        }

                        if (element.type == 1) {
                            inputs += '<div class="form-group">';
                            inputs += '<label>'+element.label+'</label>';
                            inputs += '<textarea class="form-control" data-label="'+element.label+'" name="'+element.slot+'"></textarea>';
                            inputs += '</div>';
                        }
                        if (element.type == 2) {
                            inputs += '<div class="form-group form-check">';
                            inputs += '<input id="checkbox-'+element.id+'" data-label="'+element.label+'" type="checkbox" class="form-check-input" name="'+element.slot+'"></input>';
                            inputs += '<label for="checkbox-'+element.id+'">'+element.label+'</label>';
                            inputs += '</div>';
                        }
                    })
                };
                return inputs;
            },
            nextPhrase: function(phraseId) {
                var thisTpl = this;

                var slots = Cookies.get('slots');
                if (slots && slots.length > 0) {
                    slots = JSON.parse( slots );
                } else {
                    slots = {};
                }

                if ( typeof slots === 'object' ) {
                    $('#inputs input[name]').each(function(index) {
                        if ($(this).val().length > 0) {
                            slots[$(this).attr('name')] = $(this).val();

                            if ($(this).attr('type') == 'checkbox') {
                                thisTpl.addMessageInfo($(this).data('label') + ': ' + ($(this).prop('checked') ? 'да' : 'нет')  );
                            } else {
                                thisTpl.addMessageInfo($(this).data('label') + ': ' + $(this).val());
                            }
                        }
                    });
                    Cookies.set('slots', JSON.stringify(slots));
                }

                var block = this.findBlockByPhrase(phraseId);
                $('#phrases').empty();
                $('#answers').empty();
                $('button[id^=step-]').prop('disabled', false);

                $('#step-' + block.id).prop( "disabled", true );
                var phrase = this.getPhraseById(block, phraseId);
                var phraseText = thisTpl.slotReplace(phrase.text);
                $('#phrases').html(phraseText);
                thisTpl.addMessageOut(phraseText);
                $('#talk-log').animate({ scrollTop: $('#talk-log')[0].scrollHeight }, 400);

                var answers = this.getAnswers(phrase);
                $('#answers').html(answers);
                $('.btn-answer').click(function(){
                    thisTpl.addMessageIn( $(this).html() );
                    thisTpl.nextPhrase($(this).data('next-phrase'));
                })

                var inputs = this.getInputs(phrase);
                $('#inputs').html(inputs);
            },
            // говорит менеджер
            addMessageOut: function(message) {
                $('<div class="text-left p-1 ml-5 my-2" style="background-color:#e0ffe0"><span style="display:none">Менеджер:</span>' + message + '</div>').hide().appendTo($('#talk-log')).slideDown('normal', function() {
                    $('#talk-log').animate({ scrollTop: $('#talk-log')[0].scrollHeight }, 400);
                });
            },
            // говорит клиент
            addMessageIn: function(message) {
                $('<div class="text-left p-1 mr-5 my-2" style="background-color:#e0e0ff"><span style="display:none">Клиент:</span>' + message + '</div>').hide().appendTo($('#talk-log')).slideDown('normal', function() {
                    $('#talk-log').animate({ scrollTop: $('#talk-log')[0].scrollHeight }, 400);
                });
            },
            // добавить сообщение менеджера
            addMessageInfo: function(message) {
                $('<div class="text-left p-1 mx-0 my-2" style="background-color:#ffe6af; color:#000"><span style="display:none">Ввод:</span>' + message + '</div>').hide().appendTo($('#talk-log')).slideDown('normal', function() {
                    $('#talk-log').animate({ scrollTop: $('#talk-log')[0].scrollHeight }, 400);
                });
            },
            slotReplace: function(text, slot, replace){
                var slots = Cookies.get('slots');
                if (slots && slots.length > 0) { slots = JSON.parse( slots ); }
                if ( typeof slots === 'object' ) {

                    Object.keys(slots).forEach(item => {
                        if (slots[item].length > 0) {
                            text = text.replace('{%'+item+'%}', slots[item]);
                        }
                    });
                }

                text = text.replace(/{%USER_NAME%}/g, this.managerData.name);
                return text;
            },
            stopTalk(status) {
                $('#stopScriptSale').modal('show');
                this.status = status;
            },
            submit() {
                this.fields.talk_log = $('#talk-log').html();
                this.fields.talk_log = this.fields.talk_log.replace(/<[^>]+>/ig, "\n").replace(/\n\n/ig, "\n");
                this.fields.crmEmail = this.crmEmail;
                this.fields.crmTel = this.crmTel;
                this.fields.crmName = this.crmName;
                this.fields.talk_status = this.status;
                this.fields.script_id = this.ssData.id;
                axios.post(this.routeStop, this.fields).then(response => {
                        console.log(response);
                        this.fields = {}; //Clear input fields.
                    }).catch(error => {
                        //
                    });
                setTimeout(() => {
                    window.location.href = this.routeReturn;
                }, 1000);
            }
        }
    }
</script>