<template>
    <table class="table table-sm my-3">
        <thead>
            <tr>
                <th>Описание</th>
                <th>UTM-метки</th>
                <th>Псевдоним</th>
                <th class="text-center">Управление</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item, key) in tableData" v-bind:key="item.id" class="table-item" v-bind:class="{ deleted: item.deleted }">

                <td>
                    <small class="text-black-50">
                        ID: <a v-bind:href="routeEdit + item.id">{{ item.id }}</a>,
                        {{ date_format(item.created_at) }},
                        <a :href="routeStatlink + item.id" title="Статистика" class="">Переходов: {{ item.clicks }}</a>
                    </small> <br />
                    {{ item.description }}, <br>
                    <small class="text-black-50">{{ item.url }}</small>
                </td>
                <td>
                    <small>
                        <div class="d-flex flex-wrap">
                            <div v-if="item.utm_source" title="utm_source"><b>S:</b> {{ item.utm_source }},&nbsp;</div>
                            <div v-if="item.utm_medium" title="utm_medium"><b>M:</b> {{ item.utm_medium }},&nbsp;</div>
                            <div v-if="item.utm_campaign" title="utm_campaign"><b>Cm:</b> {{ item.utm_campaign }},&nbsp;</div>
                            <div v-if="item.utm_content" title="utm_content"><b>Ct:</b> {{ item.utm_content }},&nbsp;</div>
                            <div v-if="item.utm_term" title="utm_term"><b>T:</b> {{ item.utm_term }}</div>
                        </div>
                    </small>
                </td>
                <td>
                    <div>{{ item.alias }}</div>
                    <div>
                        <button type="button" class="popover-button btn btn-outline-primary btn-sm" v-on:click="copy(item.alias, item.suffix, $event)" data-bs-toggle="popover" data-bs-content="Скопировано" data-bs-placement="top" >
                            Скопировать адрес
                        </button>
                    </div>
                </td>
                <td class="text-center">
                    <button v-if="!item.deleted" type="button" class="btn btn-outline-danger btn-sm" v-on:click="remove(item.id, key)">
                        Удалить
                    </button>
                    <button v-else type="button" class="btn btn-outline-success btn-sm" v-on:click="recovery(item.id, key)">
                        Восстановить
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
    export default {
        props: {
            shortlinks:     {type: String, required: true},
            shortclicks:    {type: String, required: true},
            routeEdit:      {type: String, required: true},
            routeRemove:    {type: String, required: true},
            routeRecovery:  {type: String, required: true},
            routeStatlink:  {type: String, required: true},
            redirectUrl:    {type: String, required: true},
        },
        data() {
            this.tableData = JSON.parse(this.shortlinks).data;

            var dataclicks = JSON.parse(this.shortclicks);
            this.tableData.forEach(function(element) {
                element.deleted = false;
                var eid =  element.id;
                element.clicks = (dataclicks[eid] == undefined) ? '0' : dataclicks[eid];
            });
            ;
            return {
                items: this.shortlinks,
                clicks: this.shortclicks,
                tableData: this.tableData,
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            }
        },
        mounted: function()  {
            var popover = new bootstrap.Popover(document.querySelector('.popover-button'), {
                container: 'body',
                delay: { "show": 500, "hide": 100 }
            });

            $('[data-toggle="popover"][data-timeout]').on('shown.bs.popover', function() {
                setTimeout( () => {
                    popover('hide');
                }, $(this).data("timeout"));
            });
        },
        methods: {
            date_format: function( date_at ) {
                var ddate = new Date(date_at);
                return ddate.getDate() + '-' + ('0' + (ddate.getMonth()+1)).slice(-2) + '-' + ddate.getFullYear() + ' ' + ddate.getHours() + ':' + ddate.getMinutes() + ':' + ddate.getSeconds();
            },
            copy: function(alias, suffix, event) {
                //event.target.popover('show');
                const el = document.createElement('textarea');
                let suf = '';
                if (typeof suffix == 'string' && suffix.length > 0) suf = suffix.replaceAll('&#123;', '{').replaceAll('&#125;', '}');

                el.value = this.redirectUrl + alias + suf;
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
            },
            remove: function(shortlink_id, index) {
                this.fields = {'shortlink_id': shortlink_id};
                axios
                    .post(this.routeRemove, this.fields)
                    .then(response => {
                        this.fields = {}; //Clear input fields.
                        this.loaded = true;
                        this.success = true;
                        this.tableData[index].deleted = true;
                    }).catch(error => {
                        this.loaded = true;
                        if (error.response.status === 422) {
                            this.errors = error.response.data.errors || {};
                        }
                    });
            },
            recovery: function(shortlink_id, index) {
                this.fields = {'shortlink_id': shortlink_id};
                axios
                    .post(this.routeRecovery, this.fields)
                    .then(response => {
                        this.fields = {}; //Clear input fields.
                        this.loaded = true;
                        this.success = true;
                        this.tableData[index].deleted = false;
                    }).catch(error => {
                        this.loaded = true;
                        if (error.response.status === 422) {
                            this.errors = error.response.data.errors || {};
                        }
                    });
            }
        }
    }
</script>
