<template>
    <form @submit.prevent="submit" method="POST" action="">
        <input type="hidden" name="_token" :value="csrf">

        <!-- Описание ссылки -->
        <div class="form-group mb-2">
            <label>*Описание ссылки <small class="text-muted">(любой текст для описания ссылки, не будет виден пользователям)</small></label>
            <input
                class="form-control"
                type="text"
                name="description"
                required
                v-model="fields.description"
            >
        </div>

        <!-- Адрес страницы -->
        <div class="form-group mb-2">
            <label>*Адрес страницы назначения <small class="text-muted">(адрес куда будет вести ссылка)</small></label>
            <input
                class="form-control"
                type="text"
                name="url"
                required
                v-model="fields.url"
                v-on:input="inputUrl($event)"
            >
            <div v-if="errors && errors.url" class="invalid-feedback">{{ errors.url[0] }}</div>
            <div>
                <a v-on:click="setURL('https://it-line.info/', $event)" href="#" class="me-3">Главная</a>
                <a v-on:click="setURL('https://it-line.info/contacts/', $event)" href="#" class="me-3">Контакты</a>
                <a v-on:click="setURL('https://www.youtube.com/c/ITLINE', $event)" href="#" class="me-3">Youtube</a>
                <a v-on:click="setURL('https://t.me/itline_tech', $event)" href="#" class="me-3">Telegram</a>
                <a v-on:click="setURL('https://vk.com/itline_tech', $event)" href="#" class="me-3">VK</a>
                <a v-on:click="setURL('https://zen.yandex.ru/id/6231954c1ad1003a981aed92', $event)" href="#" class="me-3">Zen</a>
            </div>
        </div>

        <!-- UTM -->
        <div class="row mb-2">

            <!-- utm_source -->
            <div class="form-group col-2">
                <label>utm_source</label>
                <input
                    class="form-control"
                    type="text"
                    name="utm_source"
                    placeholder=""
                    v-model="fields.utm_source"
                    v-on:input="createResult"
                >
                <small class="text-muted">
                    Источник кампании (unisender, youtube, ...)
                </small>
            </div>

            <!-- utm_medium -->
            <div class="form-group col-2">
                <label>utm_medium</label>
                <input
                    class="form-control"
                    type="text"
                    name="utm_medium"
                    placeholder=""
                    v-model="fields.utm_medium"
                    v-on:input="createResult"
                >
                <small class="text-muted">
                    Тип трафика (organic, email, banner, cpa, cpc, ...)
                </small>
            </div>

            <!-- utm_campaign -->
            <div class="form-group col-2">
                <label>utm_campaign</label>
                <input
                    class="form-control"
                    type="text"
                    name="utm_campaign"
                    placeholder=""
                    v-model="fields.utm_campaign"
                    v-on:input="createResult"
                >
                <small class="text-muted">
                    Название кампании
                </small>
            </div>

            <!-- utm_content -->
            <div class="form-group col-2">
                <label>utm_content</label>
                <input
                    class="form-control"
                    type="text"
                    name="utm_content"
                    placeholder=""
                    v-model="fields.utm_content"
                    v-on:input="createResult"
                >

                <small class="text-muted">
                    Идентификатор объявления (подкатегория товара или услуги, тип самого объявления и т. п.)
                </small>
            </div>

            <!-- utm_term -->
            <div class="form-group col-2">
                <label>utm_term</label>
                <input
                    class="form-control"
                    type="text"
                    name="utm_term"
                    placeholder=""
                    v-model="fields.utm_term"
                    v-on:input="createResult"
                >
                <small class="form-text text-muted">
                    Ключевое слово
                </small>
            </div>
        </div>

        <!-- Строка дополнительных параметров -->
        <div class="form-group mb-2">
            <label>Строка дополнительных параметров</label>
            <input
                class="form-control"
                type="text"
                name="get_params"
                placeholder=""
                v-model="fields.get_params"
                v-on:input="inputGetParams($event)"
            >
            <small class="text-muted">
                Параметры и значения должны быть кодированы (from=site&amp;page=12&amp;param7=123)
            </small>
        </div>

        <!-- Итоговая исходная ссылка -->
        <div class="form-group mb-2">
            <label>Итоговая исходная ссылка <span v-if="fields.result">({{ fields.result.length }})</span></label>
            <div class="input-group">
                <input
                    class="form-control"
                    type="text"
                    name="result"
                    readonly
                    v-model="fields.result"
                >
                <div v-if="errors && errors.result" class="invalid-feedback">{{ errors.result[0] }}</div>
            </div>
        </div>

                <div class="row mb-2">
            <div class="col-8">
                <div class="form-group">
                    <label>*Псевдоним <span v-if="fields.alias">({{ fields.alias.length +24 }})</span></label>

                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon3">{{ redirectUrl }}</span>
                        </div>

                        <input
                            class="form-control"
                            type="text"
                            name="alias"
                            required
                            v-model="fields.alias"
                            v-bind:class="{'is-invalid': (errors && errors.alias)}"
                        >
                        <div v-if="errors && errors.alias" class="invalid-feedback">{{ errors.alias[0] }}</div>
                    </div>

                    <a v-on:click="setAlias( aliasName, $event)" href="#" class="me-3">{{ aliasName }}</a>
                    <a v-on:click="setAlias('home', $event)" href="#" class="me-3">home</a>
                    <a v-on:click="setAlias('contacts', $event)" href="#" class="me-3">contacts</a>
                    <a v-on:click="setAlias('yt', $event)" href="#" class="me-3">youtube</a>
                    <a v-on:click="setAlias('tg', $event)" href="#" class="me-3">telegram</a>
                    <a v-on:click="setAlias('vk', $event)" href="#" class="me-3">VK</a>
                    <a v-on:click="setAlias('zen', $event)" href="#" class="me-3">Zen</a>
                </div>
            </div>
            <div class="col-4">
                <div class="form-group">
                    <label>Суффикс</label>
                    <input
                        class="form-control"
                        type="text"
                        name="suffix"
                        v-model="fields.suffix"
                    >
                    <a
                        href="#"
                        class="me-3"
                        v-on:click="setSuffix('?unie={{Email}}', $event)"
                    >
                        <span v-pre>?unie={{Email}}</span>
                    </a>
                </div>
            </div>
        </div>

        <!-- Кнопка «Создать» или «Сохранить» -->
        <button type="submit" class="btn btn-primary my-3 text-white">
            {{ buttonCaption }}
        </button>

        <div v-if="success" class="alert alert-success mt-3">
            Ссылка создана! Можете создать ещё одну или <a v-bind:href="routeReturn">вернуться к списку</a>.<br>
            <button
                class="btn btn-light btn-sm"
                v-on:click="copy(shortLink, $event)"
                data-toggle="popover"
                data-timeout="2000"
                data-placement="top"
                data-content="Скопировано"
            >{{ shortLink }}</button>
        </div>
    </form>

</template>

<script>
    export default {
        props: {
            routeReturn:   {type: String, required: true}, // Роут возврата
            routeSubmit:   {type: String, required: true}, // Роут отправки формы
            redirectUrl:   {type: String, required: true}, // Префикс ссылки для итогового редиректа (https://angstrem.tech/r/)
            dataShortlink: {type: String, required: false},// Данные для редактирования
            type:          {type: String, required: true}  // Тип формы (create|edit)
        },
        data() {
            var date = new Date();
            var dataAliasName = 'send' + date.getFullYear().toString().substr(-2) + ('0' + (date.getMonth()+1)).slice(-2) + ('0' + date.getDate()).slice(-2);

            var dataShortlink = {
                description: '',
                url: '',
                utm_source: '',
                utm_medium: '',
                utm_campaign: '',
                utm_content: '',
                utm_term: '',
                get_params: '',
                result: '',
                alias: dataAliasName,
                suffix: '',
            };

            // Данные для редактирования, если есть
            if (typeof this.dataShortlink !== 'undefined' && this.dataShortlink.length > 0) {
                dataShortlink = JSON.parse(this.dataShortlink);
            }

            return {
                fields: dataShortlink,
                aliasName: dataAliasName, // Alias по-умолчанию для подстановки
                errors: {},
                shortLink: '',
                posted: true,
                success: false,
                action: this.type,
                buttonCaption: this.type == 'edit' ? 'Обновить' : 'Создать',
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            }
        },
        methods: {
            inputUrl: function(event) {
                var $parts = event.target.value.match(/([^\?]*)\?(.*)/);
                if (typeof $parts != 'undefined' && $parts != null && Array.isArray($parts)) {
                    if (typeof $parts[1] == 'string') this.fields.url = $parts[1];
                    if (typeof $parts[2] == 'string') this.fields.get_params = $parts[2];
                }
                this.createResult();
            },
            inputGetParams: function(event) {
                this.fields.get_params = event.target.value;
                this.createResult();
            },
            createResult: function() {
                var result = this.fields.url;

                var params = '';
                if (this.fields.utm_source && this.fields.utm_source.length > 0) {
                    params += 'utm_source=' + this.fields.utm_source + '&';
                }
                if (this.fields.utm_medium && this.fields.utm_medium.length > 0) {
                    params += 'utm_medium=' + this.fields.utm_medium +'&';
                }
                if (this.fields.utm_campaign && this.fields.utm_campaign.length > 0) {
                    params += 'utm_campaign=' + this.fields.utm_campaign +'&';
                }
                if (this.fields.utm_content && this.fields.utm_content.length > 0) {
                    params += 'utm_content=' + this.fields.utm_content +'&';
                }
                if (this.fields.utm_term && this.fields.utm_term.length > 0) {
                    params += 'utm_term=' + this.fields.utm_term +'&';
                }
                if (this.fields.get_params && this.fields.get_params.length > 0) {
                    params += this.fields.get_params;
                }
                if (params[params.length-1] == '&') { params = params.substring(0, params.length-1); }
                if (params.length > 0) params = '?' + encodeURI(params);

                result += params;
                this.fields.result = result;
            },
            setURL: function(url, event) {
                this.fields.url = url;
                this.createResult();
                if (event) event.preventDefault();
            },
            setAlias: function(alias, event) {
                var main = null;
                if ((typeof this.fields.alias != 'undefined') && this.fields.alias.length > 0) {
                    main = this.fields.alias.match(/(.*[\d])[^\d]*$/);

                    if (typeof main != 'undefined' && main != null && typeof main == 'object') {
                        main = main[1];
                    }
                }

                if (main == null) main = '';
                this.fields.alias = main + alias;
                if (event) event.preventDefault();
            },
            setSuffix: function(suffix, event) {
                this.fields.suffix = suffix;
                if (event) event.preventDefault();
            },
            copy: function(alias, event) {
                const el = document.createElement('textarea');
                el.value = alias;
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
                if (event) event.preventDefault();
            },
            submit: function() {
                if (this.posted) {
                    this.posted = false;
                    this.success = false;
                    this.errors = {};
                    axios.post(this.routeSubmit, this.fields).then(response => {
                        let $res = JSON.parse(response.request.response);
                        this.shortLink = this.redirectUrl + $res.alias + $res.suffix.replace('&#123;&#123;', '{{').replace('&#125;&#125;', '}}');
                        this.posted = true;
                        this.success = true;
                        if (this.action == 'edit') window.location.href = this.routeReturn;
                    }).catch(error => {
                        this.posted = true;
                        if (error.response.status === 422) {
                            this.errors = error.response.data.errors || {};
                        }
                    });
                }
            }
        }
    }
</script>
