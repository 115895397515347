require('./bootstrap');

require('alpinejs');

//window.Vue = require('vue');
import Vue from 'vue'

//import BootstrapVue from 'bootstrap-vue';
//Vue.use(BootstrapVue);

Vue.component('shortlink-form', require('./components/Shortlink/ShortlinkForm.vue').default);
Vue.component('table-shortlinks', require('./components/Shortlink/TableShortlinks.vue').default);

Vue.component('sales-scripts-start', require('./components/SalesScripts/start.vue').default);


const app = new Vue({
    el: '#app',
});

$('.salesScriptList .b-start').on('click', function(){
    $('#input-id').val($(this).data('id'));
    $('#startScriptSale').modal('show');
});